var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Auth0CallbackService } from '../auth0-callback/auth0-callback.service';
import { Auth0SdkService } from '../auth0-sdk/auth0-sdk.service';
import { HttpService } from '../http/http.service';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { OptionsTransformerService } from '../options-transformer/options-transformer.service';
import { ReauthService } from '../reauth/reauth.service';
import { ValidatorService } from '../validator/validator.service';
import { WindowRefService } from '../window-ref/window-ref.service';
import { AuthError } from './models/auth-error.model';
import { ENV_WEB_BASE_URL_MAPPING, LOGOUT_TYPES, LOGOUT_URLS, REDIRECT_WHITE_LIST } from './auth-web.constants';
import { clearBrowserCookies, clearLocalstorage, urlBuilder } from '../../utils/utils';
export class AuthWebService {
    constructor(serviceFactory) {
        this.serviceFactory = serviceFactory;
        this.auth0SdkService = serviceFactory.getService(Auth0SdkService.name);
        this.auth0CallbackService = serviceFactory.getService(Auth0CallbackService.name);
        this.httpService = serviceFactory.getService(HttpService.name);
        this.localStorageService = serviceFactory.getService(LocalStorageService.name);
        this.optionsTransformerService = serviceFactory.getService(OptionsTransformerService.name);
        this.validatorService = serviceFactory.getService(ValidatorService.name);
        this.reauthService = serviceFactory.getService(ReauthService.name);
        this.windowRefService = serviceFactory.getService(WindowRefService.name);
    }
    initialise(authWebConfig) {
        return __awaiter(this, void 0, void 0, function* () {
            // set default to true
            if (authWebConfig.redirectOnAnonymous === undefined) {
                authWebConfig.redirectOnAnonymous = true;
            }
            this.authWebConfig = authWebConfig;
            this.validatorService.validateAuthWebConfig(authWebConfig);
            this.httpService.initialise(authWebConfig.env, authWebConfig.stubApiBaseUrl);
            yield this.localStorageService.initialise();
            yield this.auth0SdkService.initialise(this.optionsTransformerService.transformAuthWebConfig(authWebConfig), authWebConfig.skipAuth0SdkMock);
            // When turn on the authentication check on tab focus, verify authentication whenever tab is visible
            this.authWebConfig.verifyAuthenticationOnFocus && this._verifyAuthenticationOnFocus();
            // checking if require to redirect to ulp whenever user is unauthenticated
            // shouldRedirect value default is true
            // when shouldRedirect is true, all the behavior should remain the same
            const shouldRedirect = this._isRedirectRequired(authWebConfig);
            const response = yield this.auth0CallbackService.handleRedirectIfNeeded(authWebConfig, shouldRedirect);
            if (response.loginRequired) {
                yield this.login(Object.assign(Object.assign(Object.assign({ audience: authWebConfig.audience, maxAge: 0, prompt: 'login', appState: response.appState }, (!!(authWebConfig && authWebConfig.caller) && { caller: authWebConfig.caller })), (!!(authWebConfig && authWebConfig.channel) && { channel: authWebConfig.channel })), (!!(authWebConfig && authWebConfig.product) && { product: authWebConfig.product })));
            }
            return response.appState;
        });
    }
    /**
     * Collates all required config needed to login and then calls the auth0 SDK login lethod
     * @param {object} loginConfig, configuration for calling login
     * @returns void
     */
    login(loginConfig = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            this.validatorService.validateLoginConfig(loginConfig);
            loginConfig.appState = this.optionsTransformerService.transformAppState(loginConfig, this.authWebConfig);
            this.localStorageService.setAppState(loginConfig.appState);
            yield this.auth0SdkService.login(this.optionsTransformerService.transformLoginConfig(loginConfig)).catch((error) => __awaiter(this, void 0, void 0, function* () {
                const transformedError = this.optionsTransformerService.transformAuth0Error(error);
                const authParamOptions = {
                    authorizationParams: Object.assign(Object.assign(Object.assign({ max_age: 0, prompt: 'login' }, (!!(this.authWebConfig && this.authWebConfig.caller) && { 'ext-caller': this.authWebConfig.caller })), (!!(this.authWebConfig && this.authWebConfig.channel) && { 'ext-channel': this.authWebConfig.channel })), (!!(this.authWebConfig && this.authWebConfig.product) && { 'ext-product': this.authWebConfig.product }))
                };
                if (transformedError instanceof AuthError && transformedError.isLoginRequiredError()) {
                    yield this.auth0SdkService.login(authParamOptions);
                }
                else if (transformedError instanceof AuthError && transformedError.isReauthRequiredError()) {
                    yield this.reauthService.reauthenticate(this.authWebConfig);
                }
            }));
        });
    }
    /**
     * Checks logout method to use when logging out of auth0
     * @param {string|object} logoutConfig, expects that logout config has been provided, but will
     * also accepts a single url to support legacy logout functionality
     * @param {boolean} skipRedirect Optional, skip the redirect after logout
     * @returns void
     */
    logout(config, skipRedirect) {
        return __awaiter(this, void 0, void 0, function* () {
            const logoutConf = this.optionsTransformerService.transformLogoutConfig(config, skipRedirect !== null && skipRedirect !== void 0 ? skipRedirect : false);
            clearLocalstorage('@@auth0spajs@@');
            clearBrowserCookies('auth0');
            if (logoutConf.logoutType === LOGOUT_TYPES.OIDC) {
                yield this._logoutOidc(logoutConf.url);
            }
            if (logoutConf.logoutType === LOGOUT_TYPES.AUTH0) {
                yield this._logoutAuth0(logoutConf.url, logoutConf.skipRedirect, logoutConf.clientId);
            }
        });
    }
    /**
     * Uses the auth0 method and logs out the current session via the auth0 endpoint
     * Will redirect to a custom URL if specified
     * @param {string} url, url to use as redirect after logout
     * @param {boolean} skipRedirect, skip the redirect after logout
     * @param {string | null} clientId, clientId to logout, or null to log out of auth 0
     * @returns void
     */
    _logoutAuth0(url, skipRedirect, clientId) {
        return __awaiter(this, void 0, void 0, function* () {
            url && this.validatorService.validateLogoutUrl(url);
            yield this.auth0SdkService.logout(url || undefined, skipRedirect, clientId);
        });
    }
    /**
     * Uses the oidc method and logs out the current session via the oidc endpoint
     * Will redirect to a custom URL if specified
     * @param {string} logoutRedirectUrl Optional, url to use as redirect after logout
     * @returns void
     */
    _logoutOidc(url) {
        return __awaiter(this, void 0, void 0, function* () {
            url && this.validatorService.validateLogoutUrl(url);
            const authWebConf = this.optionsTransformerService.transformAuthWebConfig(this.authWebConfig);
            const logoutUrl = yield this.getIdToken().then(idTokenInfo => {
                const paramsObj = Object.assign(Object.assign({ client_id: authWebConf.clientId }, (!!idTokenInfo && { id_token_hint: idTokenInfo.__raw })), (url ? { post_logout_redirect_uri: url } : { post_logout_redirect_uri: `${ENV_WEB_BASE_URL_MAPPING[this.authWebConfig.env]}` }));
                const baseUrl = `https://${authWebConf.domain}${LOGOUT_URLS.OIDC}`;
                return urlBuilder(baseUrl, paramsObj);
            });
            this.windowRefService.nativeWindow.location.assign(logoutUrl);
        });
    }
    /**
     * Calls login with screen_hint: 'signup' to force the ULP template to show "signup"
     * @returns void
     */
    signup() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.login({ screenHint: 'signup' });
        });
    }
    /**
     * This function is used to determine whether the customer is currently authenticated.
     * It validates both Auth0 and old CSSO sessions.
     * @param bool
     */
    isAuthenticated(preventRedirect = false) {
        return __awaiter(this, void 0, void 0, function* () {
            const isAuth0SessionValid = yield this._isAuth0SessionValid(preventRedirect);
            if (!isAuth0SessionValid) {
                return false;
            }
            return isAuth0SessionValid;
        });
    }
    getAccessTokenSilently(getTokenConfig) {
        return __awaiter(this, void 0, void 0, function* () {
            getTokenConfig && this.validatorService.validateGetTokenConfig(getTokenConfig);
            const accessToken = yield this.auth0SdkService
                .getAccessTokenSilently(this.optionsTransformerService.transformGetTokenConfig(getTokenConfig))
                .catch((error) => __awaiter(this, void 0, void 0, function* () {
                const transformedError = this.optionsTransformerService.transformAuth0Error(error);
                const shouldRedirect = this._isRedirectRequired(this.authWebConfig);
                if (shouldRedirect) {
                    if (transformedError instanceof AuthError &&
                        (transformedError.isReauthRequiredError() || transformedError.isInvalidGrantError())) {
                        return yield this.reauthService.reauthenticate(this.authWebConfig);
                    }
                    else if (transformedError instanceof AuthError && transformedError.isLoginRequiredError()) {
                        return this._handleGetTokenError(error);
                    }
                    else {
                        return this._handleGetTokenError(error);
                    }
                }
                else {
                    throw transformedError;
                }
            }));
            return accessToken || '';
        });
    }
    /**
     * Gets an IdToken from the auth0 SDK and transforms it into a usable IdTokenInfo object
     * If an IdToken can't be obtained, it returns undefined
     * @returns {promise<IdTokenInfo>}, information from the IdToken
     */
    getIdToken() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.optionsTransformerService.transformIdToken(yield this.auth0SdkService.getIdTokenClaims());
        });
    }
    getUser() {
        return __awaiter(this, void 0, void 0, function* () {
            const auth0User = yield this.auth0SdkService.getUser();
            if (auth0User) {
                return this.optionsTransformerService.transformUser(auth0User);
            }
            if (!(yield this.auth0SdkService.isAuthenticated())) {
                throw AuthError.createLoginRequiredError();
            }
        });
    }
    /**
     * The following feature has been created for OneReg only for the moment to aid the OneReg Signup coexistence period,
     * which is why it won't be added to the `README.md`.
     * If in the future it becomes required for other consuming apps, then it can be added it to the README.
     * @param remove if true, it will remove the appState from localStorage
     */
    getAppState(remove = false) {
        const appState = this.localStorageService.getAppState();
        remove && this.localStorageService.removeAppState();
        return appState;
    }
    _isAuth0SessionValid(preventRedirect) {
        return __awaiter(this, void 0, void 0, function* () {
            return ((yield this.auth0SdkService.isAuthenticated()) ||
                !!(yield this.auth0SdkService.getAccessTokenSilently().catch((error) => __awaiter(this, void 0, void 0, function* () {
                    if (preventRedirect) {
                        return false;
                    }
                    const transformedError = this.optionsTransformerService.transformAuth0Error(error);
                    const shouldRedirect = this._isRedirectRequired(this.authWebConfig);
                    const authParamOptions = {
                        authorizationParams: Object.assign(Object.assign(Object.assign({ max_age: 0, prompt: 'login' }, (!!(this.authWebConfig && this.authWebConfig.caller) && { 'ext-caller': this.authWebConfig.caller })), (!!(this.authWebConfig && this.authWebConfig.channel) && { 'ext-channel': this.authWebConfig.channel })), (!!(this.authWebConfig && this.authWebConfig.product) && { 'ext-product': this.authWebConfig.product }))
                    };
                    if (shouldRedirect && transformedError instanceof AuthError) {
                        if (transformedError.isReauthRequiredError()) {
                            yield this.reauthService.reauthenticate(this.authWebConfig);
                        }
                        else if (transformedError.isLoginRequiredError()) {
                            yield this.auth0SdkService.login(authParamOptions);
                        }
                        else if (transformedError.isInvalidGrantError()) {
                            yield this.auth0SdkService.login(authParamOptions);
                        }
                        return false;
                    }
                    else {
                        return false;
                    }
                }))));
        });
    }
    _handleGetTokenError(error) {
        const transformedError = this.optionsTransformerService.transformAuth0Error(error);
        throw transformedError;
    }
    /**
     * The provided functionality is designed to monitor the visibility state of a window tab
     * and conduct an authentication check whenever it becomes visible.
     */
    _verifyAuthenticationOnFocus() {
        window.addEventListener('focus', () => {
            if (!document.hidden) {
                void (() => __awaiter(this, void 0, void 0, function* () {
                    yield this.auth0SdkService.initialise(this.optionsTransformerService.transformAuthWebConfig(this.authWebConfig));
                    const verifyOnFocusLoginConfig = {
                        authorizationParams: Object.assign(Object.assign(Object.assign({ max_age: 0, prompt: 'login' }, (!!(this.authWebConfig && this.authWebConfig.caller) && { 'ext-caller': this.authWebConfig.caller })), (!!(this.authWebConfig && this.authWebConfig.channel) && { 'ext-channel': this.authWebConfig.channel })), (!!(this.authWebConfig && this.authWebConfig.product) && { 'ext-product': this.authWebConfig.product }))
                    };
                    const isAuthed = yield this.auth0SdkService.isAuthenticated();
                    !isAuthed && (yield this.auth0SdkService.login(verifyOnFocusLoginConfig));
                }))();
            }
        });
    }
    /**
     * check if required to handle error or just throw it out
     */
    _isRedirectRequired(authWebConfig) {
        if (REDIRECT_WHITE_LIST.includes(authWebConfig.caller) || !authWebConfig.redirectOnAnonymous) {
            return false;
        }
        else {
            return true;
        }
    }
}
