import { GenericError } from '@auth0/auth0-spa-js';
import { Env } from '../auth-web/models/auth-web-config.model';
import { AUTH0_USER_EXTENDED_APCN, AUTH0_USER_EXTENDED_CNUMBER } from '../auth0-sdk/auth0-user-extended.model';
import { DEPRECIATION_WARNING_MESSAGE, LOGOUT_TYPES } from '../auth-web/auth-web.constants';
import { AUTH0_ERROR_INVALID_GRANT, AUTH0_ERROR_LOGIN_REQUIRED, AUTH0_ERROR_REAUTH_REQUIRED, Prompt } from '../auth0-sdk/auth0-sdk.constants';
import { DEFAULT_SCOPE, ENV_DOMAIN_MAPPING } from './options-transformer.constants';
import { AuthError } from '../auth-web';
export class OptionsTransformerService {
    transformAuthWebConfig(authWebConfig) {
        const domain = authWebConfig.stubDomain ? authWebConfig.stubDomain : ENV_DOMAIN_MAPPING[authWebConfig.env];
        const { clientId, audience, redirectUrl, scope, useLocalStorage, maxAge, screenHint } = authWebConfig;
        const auth0ClientOptions = Object.assign(Object.assign({ clientId,
            domain, authorizationParams: Object.assign(Object.assign(Object.assign(Object.assign({ redirect_uri: redirectUrl }, (!!audience && { audience })), (!!scope && { scope: `${scope} ${DEFAULT_SCOPE}` })), (!!maxAge && { max_age: maxAge })), (!!screenHint && { screen_hint: screenHint })) }, (authWebConfig.env === Env.LOCALHOST && {
            authorizeTimeoutInSeconds: 1,
            httpTimeoutInSeconds: 1
        })), (useLocalStorage && { cacheLocation: 'localstorage' }));
        return auth0ClientOptions;
    }
    transformAppState(loginConfig, authWebConfig) {
        const { caller, channel, product, skipUrlReload, signupUrl, targetUrl, maxAge } = loginConfig.appState || {};
        const _redirectUrl = loginConfig.redirectUrl || authWebConfig.redirectUrl;
        const appStateValidated = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, ((!!caller && { caller }) || (!!authWebConfig.caller && { caller: authWebConfig.caller }))), ((!!channel && { channel }) || (!!authWebConfig.channel && { channel: authWebConfig.channel }))), ((!!product && { product }) || (!!authWebConfig.product && { product: authWebConfig.product }))), (!!signupUrl && { signupUrl })), (!!targetUrl && { targetUrl })), (!!skipUrlReload && { skipUrlReload })), (!!_redirectUrl && { _redirectUrl })), ((!!maxAge && { maxAge }) || (!!authWebConfig.maxAge && { max_age: authWebConfig.maxAge })));
        return Object.keys(appStateValidated).length > 0 ? appStateValidated : undefined;
    }
    /**
     * Takes the provided login config and creates the login config
     * @param {object} config, the config provided for login
     * @returns {object} redirectLoginOptions, configuration for redirectLoginOptions
     */
    transformLoginConfig(config) {
        const authorizationParams = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, (!!config.audience && { audience: config.audience })), (!!config.scope && { scope: config.scope })), (!!config.redirectUrl && { redirect_uri: config.redirectUrl })), (!!config.emailAddress && { login_hint: config.emailAddress })), (!!config.forceLogin && { prompt: Prompt.LOGIN, max_age: 0 })), (!!config.maxAge && { max_age: config.maxAge })), (!!config.screenHint && { screen_hint: config.screenHint })), (!!config.prompt && { prompt: Prompt.LOGIN })), (!!config.caller && { 'ext-caller': config.caller })), (!!config.channel && { 'ext-channel': config.channel })), (!!config.product && { 'ext-product': config.product })), this._transformUlpCustomQueryParam(config));
        const redirectLoginOptions = Object.assign({}, (Object.keys(authorizationParams).length > 0 && { authorizationParams }));
        return Object.keys(redirectLoginOptions).length > 0 ? redirectLoginOptions : undefined;
    }
    /**
     * Takes the provided logout parameters and converts it to a standard
     * Logout config
     * @param {string|object} config, expects that logout config has been provided, but will
     * also accepts a single url to support legacy logout functionality
     * @param {boolean} skipRedirect Optional, skip the redirect after logout
     * @returns {object} config, configuration for calling logout
     */
    transformLogoutConfig(config, skipRedirect = false) {
        let logoutConfig = {};
        // these typechecks have been added temporarily and will be removed once
        // the [url] and [skipRedirect] parameters have been decommission
        if (typeof config === 'string' || config === undefined) {
            console.warn(DEPRECIATION_WARNING_MESSAGE);
            logoutConfig = Object.assign(Object.assign({ logoutType: LOGOUT_TYPES.AUTH0 }, (config && { url: config })), { skipRedirect: skipRedirect });
        }
        else if (config) {
            logoutConfig = Object.assign(Object.assign(Object.assign(Object.assign({}, (config.logoutType ? { logoutType: config.logoutType } : { logoutType: LOGOUT_TYPES.AUTH0 })), (config.url && { url: config.url })), { skipRedirect: config.skipRedirect || false }), ((config.clientId || config.clientId === null) && { clientId: config.clientId }));
            if (config.clientId || config.clientId === null)
                logoutConfig.clientId = config.clientId;
        }
        return logoutConfig;
    }
    transformGetTokenConfig(config) {
        if (config) {
            const authorizationParams = Object.assign(Object.assign({}, (!!config.audience && { audience: config.audience })), (!!config.scope && { scope: config.scope }));
            const getTokenSilentlyOptions = Object.assign(Object.assign({}, (Object.keys(authorizationParams).length > 0 && { authorizationParams })), (!!config.cacheMode && { cacheMode: config.cacheMode }));
            return Object.keys(getTokenSilentlyOptions).length > 0 ? getTokenSilentlyOptions : undefined;
        }
    }
    /**
     * Takes the provided Id Token and only includes the needed values
     * @param {obj} idToken, the idtoken obtained from the auth0 SDK
     * @returns {object|undefined} idTokenInfo, info from the idtoken
     * or undefined if the token is not provided
     */
    transformIdToken(idToken) {
        if (idToken) {
            const idTokenInfo = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ __raw: idToken.__raw }, (!!idToken.updated_at && { updated_at: idToken.updated_at })), (!!idToken.iss && { iss: idToken.iss })), (!!idToken.aud && { aud: idToken.aud })), (!!idToken.iat && { iat: idToken.iat })), (!!idToken.sub && { sub: idToken.sub })), (!!idToken.exp && { exp: idToken.exp })), (!!idToken.auth_time && { auth_time: idToken.auth_time })), (!!idToken.sid && { sid: idToken.sid })), (!!idToken.nonce && { nonce: idToken.nonce }));
            return idTokenInfo;
        }
    }
    /**
     * Takes the provided auth0 user and returns an object with the following
     * properties if they exist (email, email_verified, family_name, given_name,
     * APCN, CNUMBER
     * @param {obj} auth0User, the auth0 user
     * @returns {object|undefined} UserInfo, user details
     * or undefined if the user is not available
     */
    transformUser(auth0User) {
        if (auth0User) {
            const user = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ email: auth0User.email }, (!!auth0User.email_verified && { emailVerified: auth0User.email_verified })), (!!auth0User.family_name && { surname: auth0User.family_name })), (!!auth0User.given_name && { firstName: auth0User.given_name })), (!!auth0User[AUTH0_USER_EXTENDED_APCN] && { apcn: auth0User[AUTH0_USER_EXTENDED_APCN] })), (!!auth0User[AUTH0_USER_EXTENDED_CNUMBER] && { cNumber: auth0User[AUTH0_USER_EXTENDED_CNUMBER] }));
            return user;
        }
    }
    transformAuth0Error(error) {
        if (error instanceof GenericError && error.error === AUTH0_ERROR_LOGIN_REQUIRED) {
            return AuthError.createLoginRequiredError();
        }
        else if (error instanceof GenericError && error.message === AUTH0_ERROR_REAUTH_REQUIRED) {
            return AuthError.createReauthRequiredError();
        }
        else if (error instanceof GenericError && error.message === AUTH0_ERROR_INVALID_GRANT) {
            return AuthError.createInvalidGrantError();
        }
        else {
            return error;
        }
    }
    _transformUlpCustomQueryParam(loginConfig) {
        const appState = loginConfig.appState || {};
        return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, (!!loginConfig.hideSignup && { 'ext-hide_signup': 'true' })), (!!loginConfig.emailAlreadyRegistered && { 'ext-email_already_registered': 'true' })), (!!appState.caller && { 'ext-caller': appState.caller })), (!!appState.channel && { 'ext-channel': appState.channel })), (!!appState.product && { 'ext-product': appState.product }));
    }
}
