var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Auth0SdkService } from '../auth0-sdk/auth0-sdk.service';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { ReauthService } from '../reauth/reauth.service';
import { ValidatorService } from '../validator/validator.service';
import { WindowRefService } from '../window-ref/window-ref.service';
import { AuthError } from '../auth-web';
export class Auth0CallbackService {
    constructor(serviceFactory) {
        this.serviceFactory = serviceFactory;
        this.auth0SdkService = serviceFactory.getService(Auth0SdkService.name);
        this.localStorageService = serviceFactory.getService(LocalStorageService.name);
        this.validatorService = serviceFactory.getService(ValidatorService.name);
        this.windowRefService = serviceFactory.getService(WindowRefService.name);
        this.reauthService = serviceFactory.getService(ReauthService.name);
    }
    handleRedirectIfNeeded(authWebConfig, shouldRedirect = true) {
        return __awaiter(this, void 0, void 0, function* () {
            let appState = {};
            const callbackLoginConfig = {
                authorizationParams: Object.assign(Object.assign(Object.assign({ max_age: 0, prompt: 'login' }, (!!(authWebConfig && authWebConfig.caller) && { 'ext-caller': authWebConfig.caller })), (!!(authWebConfig && authWebConfig.channel) && { 'ext-channel': authWebConfig.channel })), (!!(authWebConfig && authWebConfig.product) && { 'ext-product': authWebConfig.product }))
            };
            // Use location.href because location.search won't work with hash routing
            const [urlWithoutQuery, locationQueryString] = this.windowRefService.nativeWindow.location.href.split('?');
            // Quick return if no query params
            if (!locationQueryString)
                return { appState };
            const shouldParseAuth0Error = locationQueryString.includes('error=') && locationQueryString.includes('error_description=');
            if (shouldParseAuth0Error) {
                const urlParams = new URLSearchParams(locationQueryString);
                const paramStringError = urlParams.get('error');
                const paramStringErrorDescription = urlParams.get('error_description');
                // when redirect is not needed, will throw error back
                if (!shouldRedirect)
                    throw new AuthError(paramStringError, paramStringErrorDescription);
                if (paramStringErrorDescription === 'reauth-required') {
                    console.log('Auth0: reauth required error');
                    yield this.reauthService.reauthenticate(authWebConfig);
                }
                else if (paramStringError === 'invalid_grant') {
                    console.log('Auth0: invalid refresh token error');
                    yield this.auth0SdkService.login(callbackLoginConfig);
                }
                else if (paramStringErrorDescription === 'stepup-error') {
                    console.log('Auth0: error on reauth step up page. Skipping.');
                    yield this.auth0SdkService.login(callbackLoginConfig);
                }
                else {
                    const redirectUrl = `${this.windowRefService.nativeWindow.location.origin}/autherror?error=${paramStringError}`;
                    this.windowRefService.nativeWindow.location.replace(redirectUrl);
                }
            }
            const shouldParseAuth0Result = locationQueryString.includes('code=') && locationQueryString.includes('state=');
            if (shouldParseAuth0Result) {
                yield this._parseAuth0Result(urlWithoutQuery);
                appState = this.localStorageService.getAppState();
                this.localStorageService.removeAppState();
                yield this._redirectToTargetIfDefined(appState);
            }
            return { appState };
        });
    }
    _parseAuth0Result(urlWithoutQuery) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield this.auth0SdkService.handleRedirectCallback();
            }
            catch (error) {
                console.warn('Error when parsing redirect from Auth0:', error.message);
            }
            this.windowRefService.nativeWindow.history.replaceState({}, document.title, urlWithoutQuery);
        });
    }
    _redirectToTargetIfDefined(appState) {
        return __awaiter(this, void 0, void 0, function* () {
            const { targetUrl } = appState;
            !!targetUrl && this.validatorService.validateTargetUrl(targetUrl);
            if (!!targetUrl && !appState.skipUrlReload && this.windowRefService.nativeWindow.location.href !== targetUrl) {
                this.windowRefService.nativeWindow.location.replace(targetUrl);
                // add a delay to give time to the new ulr to load
                yield new Promise(f => setTimeout(f, 2000));
            }
        });
    }
}
