import { AuthWebService } from './auth-web/auth-web.service';
import { Auth0CallbackService } from './auth0-callback/auth0-callback.service';
import { Auth0SdkService } from './auth0-sdk/auth0-sdk.service';
import { HttpService } from './http/http.service';
import { LocalStorageService } from './local-storage/local-storage.service';
import { OptionsTransformerService } from './options-transformer/options-transformer.service';
import { ReauthService } from './reauth/reauth.service';
import { ValidatorService } from './validator/validator.service';
import { WindowRefService } from './window-ref/window-ref.service';
export class ServiceFactory {
    getService(serviceName) {
        switch (serviceName) {
            case AuthWebService.name:
                return this.authWebService || (this.authWebService = new AuthWebService(this));
            case Auth0CallbackService.name:
                return this.auth0CallbackService || (this.auth0CallbackService = new Auth0CallbackService(this));
            case Auth0SdkService.name:
                return this.auth0SdkService || (this.auth0SdkService = new Auth0SdkService());
            case HttpService.name:
                return this.httpService || (this.httpService = new HttpService());
            case LocalStorageService.name:
                return this.localStorageService || (this.localStorageService = new LocalStorageService());
            case OptionsTransformerService.name:
                return this.optionsTransformerService || (this.optionsTransformerService = new OptionsTransformerService());
            case ValidatorService.name:
                return this.validatorService || (this.validatorService = new ValidatorService());
            case WindowRefService.name:
                return this.windowRefService || (this.windowRefService = new WindowRefService());
            case ReauthService.name:
                return this.reauthService || (this.reauthService = new ReauthService(this));
        }
    }
}
